.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon {
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 2px;
}

.action-buttons {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  gap: 10px;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
